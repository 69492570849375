import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import Container from "../common/container";
import SearchInput from "../common/searchInput";
import DropdownInput from "../common/dropdownInput";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GOODS_AND_SERVICE_LIST } from "../../actions/BarangAction";
import { GUDANG_LIST } from "../../actions/GudangAction";
import { SATUAN_LIST } from "../../actions/SatuanBarangAction";
import {
  GOODS_PER_WAREHOUSE_LIST,
  HISTORY_GOODS_LIST,
} from "../../actions/GoodsPerWarehouseAction";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { id } from "date-fns/locale";

const GoodsPerWarehouse = () => {
  const datePickerRef = useRef(null);
  const datePickerFromRef = useRef(null);
  const datePickerToRef = useRef(null);
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [show, setShow] = useState({
    type: false,
    uom: false,
    detailGoods: false,
  });
  const [tableType, setTableType] = useState("");
  const [tableData, setTableData] = useState([]);
  const [choosenUom, setChoosenUom] = useState([]);
  const [choosenData, setChoosenData] = useState();
  const [inputData, setInputData] = useState({
    type: "",
    gudang: "",
    date: "",
    uom: "",
  });
  const [date, setDate] = useState({
    from: `${
      currentMonth > 9 ? currentMonth : `0${currentMonth}`
    }/01/${currentYear}`,
    to: format(new Date(), "MM/dd/yyyy"),
  });

  const { data: barangJasaData } = useQuery(GOODS_AND_SERVICE_LIST);
  const { data: gudangData } = useQuery(GUDANG_LIST, {
    variables: {
      input: {},
    },
  });
  const { data: satuanData } = useQuery(SATUAN_LIST, {
    variables: {
      input: {
        nama: "",
      },
    },
  });
  const [getDataBarangPerSatuan, { loading, data: goodsWarehouseData }] =
    useLazyQuery(GOODS_PER_WAREHOUSE_LIST);
  const [getDataHistoryBarang, { loading: loadHistory, data: goodsHistory }] =
    useLazyQuery(HISTORY_GOODS_LIST);

  useEffect(() => {
    if (goodsWarehouseData) {
      const barangPerSatuanList = goodsWarehouseData?.barang.barangPerGudang;

      setTableData(barangPerSatuanList);
    }
  }, [goodsWarehouseData]);

  useEffect(() => {
    if (inputData.type !== "") {
      $(datePickerRef.current).datepicker({
        onSelect: (dateText) => {
          setInputData((prev) => ({ ...prev, date: dateText }));
        },
      });
    }
    // eslint-disable-next-line
  }, [inputData.type]);

  useEffect(() => {
    if (show.detailGoods) {
      $(datePickerFromRef.current).datepicker({
        onSelect: (dateText) => {
          setDate({ ...date, from: dateText });
        },
        // minDate: new Date(inputData.date),
      });
    }
    // eslint-disable-next-line
  }, [show.detailGoods]);

  useEffect(() => {
    if (show.detailGoods) {
      $(datePickerToRef.current).datepicker({
        onSelect: (dateText) => {
          setDate({ ...date, to: dateText });
        },
        minDate: new Date(date.from),
        maxDate: new Date(inputData.date),
      });
    }
    // eslint-disable-next-line
  }, [show.detailGoods, date.from]);

  useEffect(() => {
    if (show.detailGoods) {
      getDataHistoryBarang({
        variables: {
          input: {
            kodeGudang:
              inputData.type === "Gudang"
                ? inputData.gudang
                : choosenData.kodeGudang,
            kodeBarang:
              inputData.type === "Barang"
                ? inputData.gudang
                : choosenData.kodeBarang,
            tanggalDari: format(new Date(date.from), "yyyy-MM-dd"),
            tanggalSampai: format(new Date(date.to), "yyyy-MM-dd"),
          },
        },
      });
    }
  }, [show.detailGoods, date.from, date.to]);

  const dataGudang = gudangData?.Gudang.findAll.map((gudang) => ({
    id: gudang.kode_gudang,
    name: `Gudang ${gudang.nama_gudang}`,
    desc: gudang.alamat,
  }));
  const dataBarangJasa = barangJasaData?.barang.findAll.data.map((barang) => ({
    id: barang.kode_barang,
    name: barang.nama_barang,
    desc: barang.kode_barang,
  }));

  const optionData = inputData.type === "Barang" ? dataBarangJasa : dataGudang;
  const dataSatuan = satuanData?.barang.getSatuanBarang.data.map((satuan) => ({
    id: satuan.uom_id,
    name: `${satuan.uom_alias} - ${satuan.uom_name}`,
    alias: satuan.uom_name,
  }));
  const historyData = goodsHistory?.barang.historyBarang;

  const handleUomCheckbox = (option) => {
    const existedUom = choosenUom.find((type) => type === option);

    if (existedUom) {
      const deletedUom = choosenUom.filter((type) => type !== option);
      setChoosenUom(deletedUom);
    } else {
      setChoosenUom([...choosenUom, option]);
    }
  };

  const handleFilter = () => {
    if (inputData.gudang !== "") {
      getDataBarangPerSatuan({
        variables: {
          input: {
            kodeGudang: inputData.type === "Gudang" ? inputData.gudang : null,
            kodeBarang: inputData.type === "Barang" ? inputData.gudang : null,
            kodeUOM: choosenUom.map((choosen) => parseFloat(choosen)),
            tanggal: format(new Date(inputData.date), "yyyy-MM-dd"),
          },
        },
      });

      setTableType(inputData.type);
    } else {
      toast.error("Input data terlebih dahulu");
    }
  };

  const handleChooseData = (data) => {
    setShow({
      ...show,
      detailGoods: true,
    });

    setChoosenData(data);
    const fromMonth = new Date(inputData.date).getMonth() + 1;
    const fromYear = new Date(inputData.date).getFullYear();
    const fromDate = `${
      fromMonth > 9 ? fromMonth : `0${fromMonth}`
    }/01/${fromYear}`;
    const toDate = inputData.date;
    setDate({ from: fromDate, to: toDate });
  };

  return (
    <Container withoutTab>
      <div className="content_nav_module">
        <form id="" class="">
          <div class="form-wrap">
            <DropdownInput
              title="Data Barang/Gudang"
              setOpen={(e) => {
                setShow({
                  ...show,
                  type: e,
                });
              }}
            >
              {show.type && (
                <div class="popup-wrap gudang-wrap">
                  <div class="m-b-10">
                    <span
                      class="reset-list"
                      onClick={() => {
                        setInputData({
                          ...inputData,
                          type: "",
                        });
                        setShow({
                          ...show,
                          type: false,
                        });
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Reset Filter
                    </span>
                  </div>

                  <ul>
                    {["Gudang", "Barang"].map((row, i) => (
                      <li key={i}>
                        <div
                          class="text-list"
                          onClick={() => {
                            setInputData({
                              ...inputData,
                              type: row,
                              gudang: "",
                              uom: "",
                            });
                            setChoosenUom([]);
                            setTableData([]);
                            setShow({
                              ...show,
                              type: false,
                            });
                          }}
                        >
                          {row}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {inputData.type === "" ? (
                <input
                  name=""
                  type="text"
                  placeholder="Pilih di sini"
                  value=""
                  onClick={() =>
                    setShow({
                      ...show,
                      type: !show.type,
                    })
                  }
                />
              ) : (
                <ul
                  class="input-label"
                  onClick={() =>
                    setShow({
                      ...show,
                      type: !show.type,
                    })
                  }
                >
                  <li>
                    <label>{inputData.type}</label>
                  </li>
                </ul>
              )}
            </DropdownInput>
          </div>

          {inputData.type !== "" && (
            <>
              <div className="form-wrap">
                <SearchInput
                  title={`Pilih ${inputData.type}`}
                  placeholder={`Cari/Pilih ${inputData.type}`}
                  value={inputData.gudang}
                  setValue={(e) => setInputData({ ...inputData, gudang: e })}
                  searchData={optionData}
                />
              </div>

              <div class="form-wrap">
                <DropdownInput
                  title="Satuan Barang"
                  setOpen={(e) => {
                    setShow({
                      ...show,
                      uom: e,
                    });
                  }}
                >
                  {show.uom && (
                    <div class="popup-wrap gudang-wrap">
                      {dataSatuan.map((type, i) => (
                        <label key={i} class="checkbox">
                          {type.name}
                          <input
                            type="checkbox"
                            checked={choosenUom.find(
                              (choosen) => choosen === type.id
                            )}
                            onChange={() => handleUomCheckbox(type.id)}
                          />
                          <span class="checkmark"></span>
                        </label>
                      ))}
                    </div>
                  )}

                  {choosenUom.length > 0 ? (
                    choosenUom.length > 1 ? (
                      <ul
                        class="input-label"
                        onClick={() =>
                          setShow({
                            ...show,
                            uom: !show.uom,
                          })
                        }
                      >
                        <li>
                          <label>Multi Satuan</label>
                        </li>
                      </ul>
                    ) : (
                      <ul
                        class="input-label"
                        onClick={() =>
                          setShow({
                            ...show,
                            uom: !show.uom,
                          })
                        }
                      >
                        <li>
                          <label>Lihat Filter</label>
                        </li>
                      </ul>
                    )
                  ) : (
                    <input
                      name=""
                      type="text"
                      placeholder="Pilih di sini"
                      value=""
                      onClick={() =>
                        setShow({
                          ...show,
                          uom: !show.uom,
                        })
                      }
                    />
                  )}
                </DropdownInput>
              </div>

              <div className="form-wrap">
                <div className="form-container">
                  <div className="title">Tanggal</div>
                  <input
                    id="datepicker_from"
                    ref={datePickerRef}
                    className="form-date-input"
                    type="text"
                    placeholder="Pilh tanggal"
                    value={inputData.date}
                    onChange={(e) =>
                      setInputData({ ...inputData, date: e.target.value })
                    }
                  />
                  <div className="icon-form-date icon-date_ic"></div>
                </div>
              </div>
            </>
          )}

          <div class="nav-setting-wrap">
            <div class="list-setting">
              <div class="setting-btn" onClick={handleFilter}>
                <span class="icon-filter_ic"></span>
              </div>
            </div>
          </div>

          <div class="form-right-side">
            <div className="form-wrap">
              <div class="nav-setting-wrap">
                <div class="list-setting">
                  <div class="setting-btn">
                    <span class="icon-import_ic"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="data-total-wrap">
              <div>TOTAL DATA</div>
              <div class="value">{tableData?.length}</div>
            </div>
          </div>

          <div class="clear"></div>
        </form>
      </div>

      <div className="main-content-module">
        {tableData?.length > 0 ? (
          <div className="tableFixHead">
            <table id="all-data-table">
              {tableType === "Barang" ? (
                <tr>
                  <th>Gudang</th>
                  <th>Kts dalam Multi Satuan</th>
                  <th width="60%">Alamat</th>
                </tr>
              ) : (
                <tr>
                  <th width="60%">Nama Barang</th>
                  <th>Kode Barang</th>
                  <th>Multi Satuan</th>
                </tr>
              )}

              {tableData?.length === 0 ? (
                <tr>
                  <td>Tidak ada data.</td>
                </tr>
              ) : (
                tableData?.map((row, i) =>
                  tableType === "Barang" ? (
                    <tr key={i} onClick={() => handleChooseData(row)}>
                      <td>{row.gudang}</td>
                      <td>{row.stok}</td>
                      <td>{row.alamat}</td>
                    </tr>
                  ) : (
                    <tr key={i} onClick={() => handleChooseData(row)}>
                      <td>{row.barang}</td>
                      <td>{row.kodeBarang}</td>
                      <td>{row.stok}</td>
                    </tr>
                  )
                )
              )}
            </table>
          </div>
        ) : loading ? (
          <p class="sub-txt txt-center">loading...</p>
        ) : (
          <div class="main-container txt-center">
            <div class="lrg-txt">DATA KOSONG</div>
            <div class="sub-txt">
              Cari atau pilih barang dan gudang terlebih dahulu
            </div>
          </div>
        )}
      </div>

      {show.detailGoods && (
        <div class="window-overlay">
          <div class="window-container">
            <div class="window-nav">
              <div class="title flex-center">Histori Barang per Gudang</div>
              <div class="close-btn flex-center">
                <span
                  class="icon icon-close_ic"
                  onClick={() => {
                    setShow({
                      ...show,
                      detailGoods: false,
                    });
                    setChoosenData();
                    setDate({
                      from: `${
                        currentMonth > 9 ? currentMonth : `0${currentMonth}`
                      }/01/${currentYear}`,
                      to: format(new Date(), "MM/dd/yyyy"),
                    });
                  }}
                ></span>
              </div>
            </div>
            <div class="window-content">
              <div class="w-100">
                <form id="" class="">
                  <div className="form-wrap">
                    <div class="brg-name">{choosenData.barang}</div>
                    <div class="bold">{choosenData.kodeBarang}</div>
                  </div>

                  <div className="form-wrap">
                    <div className="form-container">
                      <div className="title">Tanggal</div>
                      <input
                        ref={datePickerFromRef}
                        className="form-date-input padding-r"
                        type="text"
                        placeholder="Pilh tanggal"
                        value={date.from}
                        onChange={(e) =>
                          setDate({ ...date, from: e.target.value })
                        }
                      />
                      <div className="icon-form-date icon-date_ic"></div>
                    </div>
                  </div>

                  <div class="form-wrap">
                    <span class="txt-in-form">s/d</span>
                  </div>

                  <div class="form-wrap">
                    <div className="form-container">
                      <div className="title">Tanggal</div>
                      <input
                        ref={datePickerToRef}
                        className="form-date-input"
                        type="text"
                        placeholder="Pilh tanggal"
                        value={date.to}
                        onChange={(e) =>
                          setDate({ ...date, to: e.target.value })
                        }
                      />
                      <div className="icon-form-date icon-date_ic"></div>
                    </div>
                  </div>
                  <div class="clear"></div>
                </form>
              </div>

              <div class="main-content-module">
                <div class="tableFixHead">
                  <table id="all-data-table">
                    <thead>
                      <tr>
                        <th>Tanggal</th>
                        <th>No. Sumber #</th>
                        <th>Tipe Transaksi</th>
                        <th>Masuk</th>
                        <th>Keluar</th>
                        <th>Saldo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadHistory ? (
                        <tr>
                          <td colSpan={6}>
                            <p class="sub-txt txt-center">loading...</p>
                          </td>
                        </tr>
                      ) : (
                        historyData?.length > 0 &&
                        historyData?.map((row) => (
                          <tr>
                            <td>
                              {row.tanggal !== "" &&
                                format(new Date(row.tanggal), "dd MMMM yyyy", {
                                  locale: id,
                                })}
                            </td>
                            <td>{row.noSumber}</td>
                            <td>{row.tipeTransaksi}</td>
                            <td>{row.masuk.toLocaleString("id")}</td>
                            <td>{row.keluar.toLocaleString("id")}</td>
                            <td>{row.saldo.toLocaleString("id")}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default GoodsPerWarehouse;
