import React from "react";

const DropdownInput = (props) => {
  const { title, className = "", children, disabled } = props;

  return (
    <div className={`form-container ${className}`}>
      {title && <div className="title">{title}</div>}

      <div className={`auto-form-container ${disabled && "disable"}`}>
        {children}
      </div>
      <div className="icon-form-date icon-down_ic"></div>
    </div>
  );
};

export default DropdownInput;
