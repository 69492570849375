import { gql } from "@apollo/client";

export const HISTORY_GOODS_FRAGMENT = gql`
  fragment HistoryGoods on mHistoryBarangGudang {
    tanggal
    noSumber
    tipeTransaksi
    masuk
    keluar
    saldo
  }
`;

export const GOODS_PER_WAREHOUSE_FRAGMENT = gql`
  fragment GoodsWarehouse on mBarangGudang {
    gudang
    alamat
    barang
    kodeBarang
    kodeGudang
    stok
    history {
      ...HistoryGoods
    }
  }
  ${HISTORY_GOODS_FRAGMENT}
`;

export const GOODS_PER_WAREHOUSE_LIST = gql`
  query goodsPerWarehouseList($input: BarangPerGudangInput!) {
    barang {
      barangPerGudang(input: $input) {
        ...GoodsWarehouse
      }
    }
  }
  ${GOODS_PER_WAREHOUSE_FRAGMENT}
`;

export const HISTORY_GOODS_LIST = gql`
  query historyGoodsList($input: HistoryBarangInput!) {
    barang {
      historyBarang(input: $input) {
        ...HistoryGoods
      }
    }
  }
  ${HISTORY_GOODS_FRAGMENT}
`;
