import React, { useEffect } from "react";
import Container from "../common/container";
import TabContent from "../common/tabContent";
import AddNewGoodsTransfer from "./addNew";
import GoodsTransferList from "./list";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { TRANSFER_GOODS_LIST } from "../../actions/TransferGoodsAction";

const GoodsTransferModul = () => {
  const [tabSession, setTabSession] = React.useState([
    { label: "Lihat Data", type: "list", id: 0 },
    { label: "Buat Baru", type: "add-new", id: 1 },
  ]);
  const [activeTabId, setActiveTabId] = React.useState(1);
  const [choosenData, setChoosenData] = React.useState([]);

  const [
    getGoodsTransferList,
    { loading, error, data: dataGoodsTransfer, refetch },
  ] = useLazyQuery(TRANSFER_GOODS_LIST);

  useEffect(() => {
    getGoodsTransferList();
    // eslint-disable-next-line
  }, []);

  if (error) {
    toast.error(error.message);
  }

  const activeData = tabSession.find((tab) => tab.id === activeTabId);

  const handleOpenDetail = (choosen) => {
    const foundData = dataGoodsTransfer?.PemindahanBarang.findAll.find(
      (row) => row.noPemindahan === choosen
    );
    if (tabSession.find((tab) => tab.id === choosen)) {
      setTabSession([...tabSession]);
    } else {
      setTabSession([
        ...tabSession,
        {
          label: choosen,
          type: "detail",
          id: choosen,
        },
      ]);
    }
    setActiveTabId(choosen);
    setChoosenData([
      ...choosenData,
      { idTab: choosen, ...foundData },
    ]);
  };

  return (
    <div>
      <TabContent
        active={activeTabId}
        setActive={setActiveTabId}
        tabs={tabSession}
        setTabs={setTabSession}
      />

      <Container>
        {activeData.type === "list" ? (
          <GoodsTransferList
            tabs={tabSession}
            setTabs={setTabSession}
            setActive={setActiveTabId}
            choosenData={choosenData}
            setChoosenData={setChoosenData}
            dataGoodsTransfer={dataGoodsTransfer}
            loading={loading}
          />
        ) : activeData.type === "add-new" ? (
          <AddNewGoodsTransfer
            dataGoodsTransfer={dataGoodsTransfer}
            setTabOpen={setActiveTabId}
            refetch={refetch}
          />
        ) : (
          <AddNewGoodsTransfer
            detail={true}
            choosenData={choosenData.find(
              (choosen) => choosen.idTab === activeTabId
            )}
            dataPerTabs={choosenData}
            setChoosenData={setChoosenData}
            tabs={tabSession}
            setTabs={setTabSession}
            tabOpen={activeTabId}
            setTabOpen={setActiveTabId}
            refetch={refetch}
            dataGoodsTransfer={dataGoodsTransfer}
            handleRedirectDetail={handleOpenDetail}
          />
        )}
      </Container>
    </div>
  );
};

export default GoodsTransferModul;
